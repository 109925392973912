<template>
  <div class="flex flex-col sm:flex-row items-start mx-2">
    <div class="w-full sm:w-1/2 sm:mr-2 lg:mr-0">
      <div v-if="platform === 'bikeable'"
           class="relative h-full w-full">
        <img src="~assets/road-bikeable.svg"
             class="w-full h-full"
             :alt="$t('accessibility.road_image')" />
        <img class="absolute bottom-[8%] right-[10%] sm:-bottom-0.5 sm:right-0 lg:bottom-[6%] lg:right-[20%] rotate-6 sm:rotate-2 h-[260px] sm:h-[300px]"
             src="~assets/mascot-bikeable.png"
             :alt="$t('accessibility.mascot_neutral')">
        <!--        <a href="" target="_blank">-->
        <!--        <NuxtImg-->
        <!--            class="absolute left-2 sm:left-0 md:left-2 top-0 w-[70px] rounded-[35px] sm:w-[150px] sm:rounded-[75px] shadow-xl"-->
        <!--            :src="`sticker/bikeable-Sticker-${language?.toUpperCase()}.svg`"/>-->
        <!--        </a>-->
      </div>
      <div v-if="platform === 'walkable'"
           class="relative h-full w-full">
        <img src="~assets/road-walkable.svg"
             class="w-full h-full"
             :alt="$t('accessibility.road_image')" />
        <img class="absolute bottom-[12%] right-[5%] lg:right-[15%] rotate-6 h-[220px] sm:h-[250px] md:h-[300px]"
             src="~assets/mascot-walkable.png"
             alt="mascot">
        <!--        <a href="" target="_blank">-->
        <!--          <NuxtImg-->
        <!--              class="absolute left-2 sm:left-0 md:left-2 top-0 w-[70px] rounded-[35px] sm:w-[150px] sm:rounded-[75px] shadow-xl"-->
        <!--              :src="`sticker/walkable-Sticker-${language?.toUpperCase()}.svg`"/>-->
        <!--        </a>-->
      </div>
    </div>
    <div class="w-full sm:w-1/2 flex flex-col gap-1 lg:-translate-x-[10%]">
      <h1 class="font-black text-theme-primary mt-[1em]">{{ $t("general.slogan_" + platform) }}</h1>
      <p class="leading-relaxed">
        {{ $t("home.explanation_" + platform) }}
      </p>
    </div>
  </div>
  <div class="flex flex-col w-full items-start mt-4 px-1">
    <h2>{{ $t('home.new_spots_title') }}</h2>
  </div>
  <div class="flex flex-col w-full mb-4 px-1">
    <SpotList v-if="platform"
              :only-region-filter="true"
              :page-size="6" />
  </div>
  <div class="flex flex-col w-full items-start mt-4 px-1">
    <h2>{{ $t('news.page_title') }}</h2>
  </div>
  <div class="flex flex-col w-full mb-4 px-1">
    <NewsList :pageSize="5" />
  </div>
</template>
<script setup lang="ts">

definePageMeta({
  layout: "home"
});
const platform = usePlatformSettings().platformName;

</script>